import React from 'react';
import {
    translate,
    Datagrid,
    Edit,
    Create,
    // EditButton,
    List,
    SimpleForm,
    TextField,
    ReferenceField,
    ReferenceInput,
    SelectInput,
    ReferenceArrayInput,
    SelectArrayInput,
    ReferenceArrayField,
    SingleFieldList,
    ChipField,
    Filter,
    EditButton,
    TextInput,
    CreateButton,
} from 'react-admin';
import qs from 'qs';
import withStyles from '@material-ui/core/styles/withStyles';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import Toolbar from '@material-ui/core/Toolbar';

import resourceLocales from './locales';
import resourceConfig from './config';

import LinkToList from './LinkToList';
import exporter from '../../../../utils/exporter';
import erpH from '../erpH';
import erpKMupload from '../erpKMupload';
import ExportDocButton from '../docH/ExportDocButton';

const ResourceIcon = BookmarkIcon;

const resourceToString = (x) => `#${x.id} (${x.docnum})`;

const listStyles = {};

const ResourceActions = ({
                             bulkActions,
                             basePath,
                             currentSort,
                             displayedFilters,
                             exporter,
                             filters,
                             filterValues,
                             onUnselectItems,
                             resource,
                             selectedIds,
                             showFilter,
                             total,
                         }) => (
    <Toolbar>
        {bulkActions &&
            React.cloneElement(bulkActions, {
                basePath,
                filterValues,
                resource,
                selectedIds,
                onUnselectItems,
            })}
        {filters &&
            React.cloneElement(filters, {
                resource,
                showFilter,
                displayedFilters,
                filterValues,
                context: 'button',
            })}
        <CreateButton
            basePath={basePath}
            to={`${basePath}/create?${
                filterValues && filterValues.overEntityIds ? `_docErpHId=${filterValues.overEntityIds}` : ''
            }`}
        />
        <ExportDocButton
            disabled={total === 0}
            resource={resource}
            sort={currentSort}
            filter={filterValues}
            exporter={exporter}
        />
        <erpKMupload.OpenButton/>
    </Toolbar>
);

const ResourceListFilter = (props) => (
    <Filter {...props}>
        <erpH.Input source={'overEntityIds'} label="overEntityIds"/>
        <TextInput source={'num_goods'}/>
    </Filter>
);

const ResourceList = withStyles(listStyles)(({classes, ...props}) => (
    <List
        {...props}
        filters={<ResourceListFilter/>}
        sort={{field: 'id', order: 'DESC'}}
        actions={<ResourceActions/>}
        exporter={exporter}>
        <Datagrid>
            <TextField source={'id'}/>
            <erpH.Field source={'docErpHId'}/>
            <TextField source={'num_goods'}/>
            <TextField source={'algorithm'}/>
            <TextField source={'uit_code'}/>
            <EditButton/>
        </Datagrid>
    </List>
));

const ResourceTitle = translate(({record, translate}) => (
    <span>
    {translate(`resources.${resourceConfig.name}.name`, {smart_count: 1})} &quot;{record.name}
        &quot;
  </span>
));

const ResourceCreate = (props) => {
    const defaultData = {};
    if (props.location && props.location.search && props.location.search.length > 1) {
        const params = qs.parse(props.location.search.replace(/^\?/, ''));
        if (params && params._docErpHId) {
            defaultData.docErpHId = parseInt(params._docErpHId);
        }
    }
    return (
        <Create {...props}>
            <SimpleForm>
                <erpH.Input source={'docErpHId'} defaultValue={defaultData.docErpHId}/>
                <TextInput source={'algorithm'}/>
                <TextInput source={'ki'}/>
                <TextInput source={'num_goods'}/>
                <TextInput source={'uit_code'}/>
                <TextInput source={'uitu_code'}/>
                <TextInput source={'exStatus'}/>
                <TextInput source={'productGroup'}/>
                <TextInput source={'full_km'}/>
                <TextInput source={'cashierError'}/>
            </SimpleForm>
        </Create>
    );
};

const ResourceEdit = (props) => (
    <Edit redirect={null} undoable={false} title={<ResourceTitle/>} {...props}>
        <SimpleForm redirect={false}>
            <erpH.Input source={'docErpHId'}/>
            <TextInput source={'algorithm'}/>
            <TextInput source={'ki'}/>
            <TextInput source={'num_goods'}/>
            <TextInput source={'uit_code'}/>
            <TextInput source={'uitu_code'}/>
            <TextInput source={'exStatus'}/>
            <TextInput source={'productGroup'}/>
            <TextInput source={'full_km'}/>
            <TextInput source={'cashierError'}/>
        </SimpleForm>
    </Edit>
);

const ResourceStringField = ({record}) => <span>{resourceToString(record)}</span>;

// const ResourceStringChipField = ({ record }) => (
//     <span>{resourceToString(record)}</span>
// )

const ResourceReferenceField = (props) => (
    <ReferenceField reference={resourceConfig.name} {...props}>
        <ResourceStringField/>
    </ReferenceField>
);

const ResourceReferenceArrayField = (props) => (
    <ReferenceArrayField reference={resourceConfig.name} {...props}>
        <SingleFieldList>
            <ChipField source={'id'}/>
        </SingleFieldList>
    </ReferenceArrayField>
);

const ResourceReferenceInput = (props) => (
    <ReferenceInput reference={resourceConfig.name} {...props}>
        <SelectInput optionText={resourceToString}/>
    </ReferenceInput>
);

const ResourceReferenceArrayInput = (props) => (
    <ReferenceArrayInput reference={resourceConfig.name} {...props}>
        <SelectArrayInput optionText={resourceToString}/>
    </ReferenceArrayInput>
);

export default {
    Icon: ResourceIcon,
    List: ResourceList,
    Title: ResourceTitle,
    Create: ResourceCreate,
    Edit: ResourceEdit,
    Field: ResourceReferenceField,
    ArrayField: ResourceReferenceArrayField,
    Input: ResourceReferenceInput,
    ArrayInput: ResourceReferenceArrayInput,
    LinkToList,

    name: resourceConfig.name,
    resourceConfig: {
        name: resourceConfig.name,
        list: ResourceList,
        edit: ResourceEdit,
        create: ResourceCreate,
        icon: ResourceIcon,
    },
    locales: resourceLocales,
    restProviderResolve: {
        name: resourceConfig.name,
        apiService: resourceConfig.apiService,
        basePath: resourceConfig.apiPath,
        schema: {},
    },
};
