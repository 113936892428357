import React from 'react';
import {
  translate,
  Datagrid,
  Edit,
  Create,
  List,
  SimpleForm,
  TextField,
  TextInput,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  ReferenceArrayInput,
  SelectArrayInput,
  ReferenceArrayField,
  SingleFieldList,
  ChipField,
  EditButton,
  Filter,
  CreateButton,
  NumberInput,
  BooleanInput,
} from 'react-admin';
import withStyles from '@material-ui/core/styles/withStyles';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import Toolbar from '@material-ui/core/Toolbar';

import resourceLocales from './locales';
import resourceConfig from './config';
import documentRowStyle from '../../../../utils/ui/documentRowStyle';
import DateInput from '../../../../components/DateInput';
import exporter from '../../../../utils/exporter';
import erpGoods from '../erpGoods';
import erpKM from '../erpKM';
import PostPagination from '../../../../utils/pagination';
import ExportDocButton from '../docH/ExportDocButton';

const ResourceIcon = BookmarkIcon;

const resourceToString = (x) => `#${x.id}`;


const GoodsButton = (props) => {
  return (
    <erpGoods.LinkToList
      label={`resources.${resourceConfig.name}.erpGoods`}
      filter={{
        overEntityIds: props.record.id,
      }}
    />
  );
};
const KMButton = (props) => {
  return (
    <erpKM.LinkToList
      label={`resources.${resourceConfig.name}.erpKM`}
      filter={{
        overEntityIds: props.record.id,
      }}
    />
  );
};

const ResourceListFilter = (props) => (
  <Filter {...props}>
    <TextInput source={'docNumErp'} />
  </Filter>
);

const ResourceActions = ({
  bulkActions,
  basePath,
  currentSort,
  displayedFilters,
  exporter,
  filters,
  filterValues,
  onUnselectItems,
  resource,
  selectedIds,
  showFilter,
  total,
}) => (
  <Toolbar>
    {bulkActions &&
      React.cloneElement(bulkActions, {
        basePath,
        filterValues,
        resource,
        selectedIds,
        onUnselectItems,
      })}
    {filters &&
      React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button',
      })}
    <CreateButton basePath={basePath} />
    <ExportDocButton
      disabled={total === 0}
      resource={resource}
      sort={currentSort}
      filter={filterValues}
      exporter={exporter}
      basePath={basePath}
      resourceConfig={resourceConfig}
    />
  </Toolbar>
);

const ResourceList = withStyles()(({ classes, ...props }) => (
  <List
    {...props}
    actions={<ResourceActions />}
    filters={<ResourceListFilter />}
    sort={{ field: 'id', order: 'DESC' }}
    exporter={exporter}
    pagination={<PostPagination />}>
    <Datagrid rowStyle={documentRowStyle}>
      <TextField source={'id'} />
      <TextField source={'docDateErp'} />
      <TextField source={'docNumErp'} />
      <TextField source={'docTypeErp'} />
      <TextField source={'doc_num_idocs'} />
      <TextField source={'doc_type_idocs'} />
      <GoodsButton />
      <KMButton />
      <EditButton />
    </Datagrid>
  </List>
));

const ResourceTitle = translate(({ record, translate }) => (
  <span>
    {translate(`resources.${resourceConfig.name}.name`, { smart_count: 1 })} &quot;{record.id}&quot;
  </span>
));

const ResourceCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <DateInput
        source={'acceptance_date'}
        label={`resources.${resourceConfig.name}.fields.acceptance_date`}
      />
      <TextInput source={'action'} />
      {/* RETAIL */}

      <TextInput source={'actionId'} />
      <DateInput
        source={'action_date'}
        label={`resources.${resourceConfig.name}.fields.action_date`}
      />
      <DateInput
        source={'certificate_date'}
        label={`resources.${resourceConfig.name}.fields.certificate_date`}
      />
      <TextInput source={'certificate_number'} />
      <TextInput source={'certificate_type'} />
      {/* CONFORMITY_CERTIFICATE */}

      <TextInput source={'checkStatus'} />
      <TextInput source={'direction'} />
      {/* IN */}

      <DateInput
        source={'docDateErp'}
        label={`resources.${resourceConfig.name}.fields.docDateErp`}
      />
      <TextInput source={'docNumErp'} />
      <TextInput source={'docStatus'} />
      {/* UPLOADING_DOCUMENT */}

      <TextInput source={'docTypeErp'} />
      <DateInput
        source={'doc_date_idocs'}
        label={`resources.${resourceConfig.name}.fields.doc_date_idocs`}
      />
      <TextInput source={'doc_num_idocs'} />
      <TextInput source={'doc_type_idocs'} />
      {/* DOC */}

      <TextInput source={'docnum'} />
      <TextInput source={'documentId'} />
      <NumberInput source={'documentLinkId'} />
      <TextInput source={'documentLinkType'} />
      {/* DOC */}

      <TextInput source={'documentSystemStatus'} />
      {/* NEW */}

      <DateInput
        source={'document_date'}
        label={`resources.${resourceConfig.name}.fields.document_date`}
      />
      <TextInput source={'document_number'} />
      <NumberInput source={'errorModelId'} />
      <TextInput source={'errors'} />
      <BooleanInput source={'flag_create'} />
      <TextInput source={'guid'} />
      <TextInput source={'ismpDocType'} />
      <TextInput source={'out_doc_type'} />
      {/* RECEIPT */}

      <TextInput source={'owner'} />
      <TextInput source={'owner_inn'} />
      <BooleanInput source={'paid'} />
      <TextInput source={'primary_document_custom_name'} />
      <DateInput
        source={'primary_document_date'}
        label={`resources.${resourceConfig.name}.fields.primary_document_date`}
      />
      <TextInput source={'primary_document_number'} />
      <TextInput source={'primary_document_type'} />
      {/* RECEIPT */}

      <DateInput
        source={'processedDate'}
        label={`resources.${resourceConfig.name}.fields.processedDate`}
      />
      {/* <TextInput source={'productGroup'} /> */}
      <TextInput source={'receiver'} />
      <TextInput source={'receiverId'} />
      <TextInput source={'receiver_inn'} />
      <TextInput source={'release_order_number'} />
      <TextInput source={'requestId'} />
      <TextInput source={'request_type'} />
      {/* SHIPMENT */}

      <TextInput source={'return_type'} />
      {/* RETAIL_RETURN */}

      <TextInput source={'sender'} />
      <TextInput source={'senderInn'} />
      <TextInput source={'subjectId'} />
      <TextInput source={'tradeRecipientInn'} />
      <TextInput source={'tradeSender_name'} />
      <TextInput source={'trade_owner_inn'} />
      <TextInput source={'trade_owner_name'} />
      <TextInput source={'trade_participant_inn'} />
      <TextInput source={'trade_sender_inn'} />
      <DateInput
        source={'transfer_date'}
        label={`resources.${resourceConfig.name}.fields.transfer_date`}
      />
      <TextInput source={'turnover_type'} />
      {/* SALE */}

      <TextInput source={'warehouseFrom'} />
      <TextInput source={'warehouseTo'} />

      <BooleanInput source={'needSSCC'} />

      <BooleanInput source={'needVirtualSSCC'} />
      <TextInput source={'virtualSSCC'} />
      <TextInput source={'torg2'} />
      <BooleanInput source={'virtual'} />
      <BooleanInput source={'tableCreated'} />
      <BooleanInput source={'shippingNotice'} />
      <BooleanInput source={'multiplicity'} />
      <TextInput source={'cashierReqId'} />
      <TextInput source={'cashierReqTimestamp'} />
      <TextInput source={'cashierCheckResult'} />
    </SimpleForm>
  </Create>
);

const ResourceEdit = (props) => (
  <Edit redirect={null} undoable={false} title={<ResourceTitle />} {...props}>
    <SimpleForm redirect={false}>
      <DateInput
        source={'acceptance_date'}
        label={`resources.${resourceConfig.name}.fields.acceptance_date`}
      />
      <TextInput source={'action'} />
      {/* RETAIL */}

      <TextInput source={'actionId'} />
      <DateInput
        source={'action_date'}
        label={`resources.${resourceConfig.name}.fields.action_date`}
      />
      <DateInput
        source={'certificate_date'}
        label={`resources.${resourceConfig.name}.fields.certificate_date`}
      />
      <TextInput source={'certificate_number'} />
      <TextInput source={'certificate_type'} />
      {/* CONFORMITY_CERTIFICATE */}

      <TextInput source={'checkStatus'} />
      <TextInput source={'direction'} />
      {/* IN */}

      <DateInput
        source={'docDateErp'}
        label={`resources.${resourceConfig.name}.fields.docDateErp`}
      />
      <TextInput source={'docNumErp'} />
      <TextInput source={'docStatus'} />
      {/* UPLOADING_DOCUMENT */}

      <TextInput source={'docTypeErp'} />
      <DateInput
        source={'doc_date_idocs'}
        label={`resources.${resourceConfig.name}.fields.doc_date_idocs`}
      />
      <TextInput source={'doc_num_idocs'} />
      <TextInput source={'doc_type_idocs'} />
      {/* DOC */}

      <TextInput source={'docnum'} />
      <TextInput source={'documentId'} />
      <NumberInput source={'documentLinkId'} />
      <TextInput source={'documentLinkType'} />
      {/* DOC */}

      <TextInput source={'documentSystemStatus'} />
      {/* NEW */}

      <DateInput
        source={'document_date'}
        label={`resources.${resourceConfig.name}.fields.document_date`}
      />
      <TextInput source={'document_number'} />
      <NumberInput source={'errorModelId'} />
      <TextInput source={'errors'} />
      <BooleanInput source={'flag_create'} />
      <TextInput source={'guid'} />
      <TextInput source={'ismpDocType'} />
      <TextInput source={'out_doc_type'} />
      {/* RECEIPT */}

      <TextInput source={'owner'} />
      <TextInput source={'owner_inn'} />
      <BooleanInput source={'paid'} />
      <TextInput source={'primary_document_custom_name'} />
      <DateInput
        source={'primary_document_date'}
        label={`resources.${resourceConfig.name}.fields.primary_document_date`}
      />
      <TextInput source={'primary_document_number'} />
      <TextInput source={'primary_document_type'} />
      {/* RECEIPT */}

      <DateInput
        source={'processedDate'}
        label={`resources.${resourceConfig.name}.fields.processedDate`}
      />
      {/* <TextInput source={'productGroup'} /> */}
      <TextInput source={'receiver'} />
      <TextInput source={'receiverId'} />
      <TextInput source={'receiver_inn'} />
      <TextInput source={'release_order_number'} />
      <TextInput source={'requestId'} />
      <TextInput source={'request_type'} />
      {/* SHIPMENT */}

      <TextInput source={'return_type'} />
      {/* RETAIL_RETURN */}

      <TextInput source={'sender'} />
      <TextInput source={'senderInn'} />
      <TextInput source={'subjectId'} />
      <TextInput source={'tradeRecipientInn'} />
      <TextInput source={'tradeSender_name'} />
      <TextInput source={'trade_owner_inn'} />
      <TextInput source={'trade_owner_name'} />
      <TextInput source={'trade_participant_inn'} />
      <TextInput source={'trade_sender_inn'} />
      <DateInput
        source={'transfer_date'}
        label={`resources.${resourceConfig.name}.fields.transfer_date`}
      />
      <TextInput source={'turnover_type'} />
      {/* SALE */}

      <TextInput source={'warehouseFrom'} />
      <TextInput source={'warehouseTo'} />

      <BooleanInput source={'needSSCC'} />

      <BooleanInput source={'needVirtualSSCC'} />
      <TextInput source={'virtualSSCC'} />
      <TextInput source={'torg2'} />
      <BooleanInput source={'virtual'} />
      <BooleanInput source={'tableCreated'} />
      <BooleanInput source={'shippingNotice'} />
      <BooleanInput source={'multiplicity'} />
      <TextInput source={'cashierReqId'} />
      <TextInput source={'cashierReqTimestamp'} />
      <TextInput source={'cashierCheckResult'} />
    </SimpleForm>
  </Edit>
);

const ResourceStringField = ({ record }) => <span>{resourceToString(record)}</span>;

const ResourceReferenceField = (props) => (
  <ReferenceField reference={resourceConfig.name} {...props}>
    <ResourceStringField />
  </ReferenceField>
);

const ResourceReferenceArrayField = (props) => (
  <ReferenceArrayField reference={resourceConfig.name} {...props}>
    <SingleFieldList>
      <ChipField source={'id'} />
    </SingleFieldList>
  </ReferenceArrayField>
);

const ResourceReferenceInput = (props) => (
  <ReferenceInput reference={resourceConfig.name} {...props}>
    <SelectInput optionText={resourceToString} />
  </ReferenceInput>
);

const ResourceReferenceArrayInput = (props) => (
  <ReferenceArrayInput reference={resourceConfig.name} {...props}>
    <SelectArrayInput optionText={resourceToString} />
  </ReferenceArrayInput>
);

export default {
  Icon: ResourceIcon,
  List: ResourceList,
  Title: ResourceTitle,
  Create: ResourceCreate,
  Edit: ResourceEdit,
  Field: ResourceReferenceField,
  ArrayField: ResourceReferenceArrayField,
  Input: ResourceReferenceInput,
  ArrayInput: ResourceReferenceArrayInput,

  name: resourceConfig.name,
  resourceConfig: {
    name: resourceConfig.name,
    list: ResourceList,
    edit: ResourceEdit,
    create: ResourceCreate,
    icon: ResourceIcon,
  },
  menuConfig: {
    name: resourceConfig.name,
    icon: ResourceIcon,
  },
  locales: resourceLocales,
  restProviderResolve: {
    name: resourceConfig.name,
    apiService: resourceConfig.apiService,
    basePath: resourceConfig.apiPath,
    schema: {
      acceptance_date: {
        type: 'dateTime',
      },
      action_date: {
        type: 'dateTime',
      },
      certificate_date: {
        type: 'dateTime',
      },
      docDateErp: {
        type: 'dateTime',
      },
      doc_date_idocs: {
        type: 'dateTime',
      },
      document_date: {
        type: 'dateTime',
      },
      primary_document_date: {
        type: 'dateTime',
      },
      processedDate: {
        type: 'dateTime',
      },
      transfer_date: {
        type: 'dateTime',
      },
    },
  },
};
