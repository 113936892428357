export default {
    enums: [
        {id: 'clothes'},
        {id: 'shoes'},
        {id: 'tobacco'},
        {id: 'perfumery'},
        {id: 'tires'},
        {id: 'electronics'},
        {id: 'pharma'},
        {id: 'milk'},
        {id: 'petfood'},
        {id: 'bicycle'},
        {id: 'wheelchairs'},
        {id: 'bio'},
        {id: 'water'}
    ]
};









